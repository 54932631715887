<template>
  <div class="app-container">
    <div class="banner">
      <img src="@/assets/img/xinren@2x.png" alt="" />
    </div>
    <div>
      <van-form @submit="onSubmit">
        <van-field
          v-model="username"
          name="username"
          label="姓名"
          placeholder="姓名"
          :rules="[{ required: true, message: '请输入姓名' }]"
        />
        <van-field
          v-model="phone"
          name="phone"
          label="手机号"
          placeholder="手机号"
          :rules="[
            { required: true, message: '请输入手机号' },
            { validator: validatorPhone, message: '请输入正确手机号' },
          ]"
        />
        <van-field
          clickable
          name="area"
          :value="area"
          label="地区"
          placeholder="地区"
          @click="showArea = true"
          :rules="[{ required: true, message: '请选择地区' }]"
        />
        <van-popup v-model="showArea" position="bottom">
          <van-area
            :area-list="areaList"
            @confirm="onConfirm"
            :value="city"
            @cancel="showArea = false"
          />
        </van-popup>
        <van-field
          v-model="address"
          name="address"
          label="详细地址"
          rows="3"
          type="textarea"
          placeholder="详细地址"
          :rules="[
            { required: true, message: '请输入详细地址' },
          ]"
        />
        <div style="margin: 16px">
          <van-button round block type="danger" native-type="submit"
            >提交</van-button
          >
        </div>
      </van-form>
    </div>
  </div>
</template>
<script>
import qs from 'qs'
import axios from 'axios'
import { areaList } from '@vant/area-data'
import { setUserInfo } from '@/utils/index'
export default {
  data () {
    return {
      username: '',
      phone: '',
      address: '',
      area: '',
      city: '341122', // 341122
      showArea: false,
      areaList: areaList,
      areaCode: '', // 地区编码
      openid: '',
      code: ''
    }
  },
  methods: {
    validatorPhone (val) {
      return /^1[3-9]\d{9}$/.test(val)
    },
    onSubmit (values) {
      console.log('submit', values)
      this.$http.post('/mall_wx/users', {
        user_name: this.username,
        phone: this.phone,
        // openid: this.openid || '123456',
        code: this.code,
        address: `${this.area}|${this.address}`,
        area_code: this.areaCode
      }).then(res => {
        this.$toast.fail({
          message: '注册成功！',
          onClose: () => {
            localStorage.clear()
            setUserInfo(res.data)
            this.$router.push('/index')
          }
        })
      })
    },
    onConfirm (val) {
      const str = val[0].name + val[1].name + val[2].name
      console.log('地址', val)
      this.areaCode = val[2].code // 地区编码
      this.area = str
      this.showArea = false
    },
    /**
     * @description: 获取授权code
     * @param {*}
     * @return {*}
     */
    getCode () {
      // 从 window.location.href 中截取 code 并且赋值
      // window.location.href.split('#')[0]
      if (window.location.href.indexOf('state') !== -1) {
        this.code = qs.parse(
          window.location.href.split('#')[0].split('?')[1]
        ).code
      }

      if (this.code) {
        console.log('code', this.code)
        // 存在 code 直接调用接口
        // this.handGetUserInfo(this.code)
      } else {
        this.handLogin()
      }
    },
    /**
     * @description: 获取用户授权登陆
     * @param {*}
     * @return {*}
     */
    handLogin () {
      // 重定向地址重定到当前页面，在路径获取 code
      const hrefUrl = window.location.href
      // const hrefUrl = 'https://www.laianjiufu.cn/#/register'
      console.log('hrefUrl', hrefUrl)
      if (this.code === '') {
        window.location
          .replace(`https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx8e50a21bf146f210&redirect_uri=${encodeURIComponent(hrefUrl)}&response_type=code&scope=snsapi_base&state=h5#wechat_redirect`)
      }
    },
    /**
     * @description: 获取用户信息
     * @param {*} code
     * @return {*}
     */
    handGetUserInfo (code) {
      // 跨域问题无法使用
      let url = `/wxapi/sns/oauth2/access_token?appid=wx8e50a21bf146f210&secret=c4699fdea1a184852897af4c7790e37e&code=${this.code}&grant_type=authorization_code`
      if (process.env.NODE_ENV === 'production') {
        url = `https://api.weixin.qq.com/api/sns/oauth2/access_token?appid=wx8e50a21bf146f210&secret=c4699fdea1a184852897af4c7790e37e&code=${this.code}&grant_type=authorization_code`
      }
      axios.get(url)
        .then(res => {
          this.openid = res.data.openid
          console.log('openid', res.data.openid)
        })
    }
  },
  created () {
    this.getCode()
  }
}
</script>
<style lang="less" scoped>
.banner {
  img {
    width: 375px;
    height: 210px;
  }
}
</style>
