<template>
    <div class="backHome">
      <van-icon name="wap-home" color="#1989fa" size="20" @click="$router.push('/index')" />
      <span @click="$router.push('/index')">返回首页</span>
    </div>
</template>
<script>
export default {
  name: 'backHome'
}
</script>
<style lang="less" scoped>
.backHome {
  display: flex;
  align-items: center;
  padding: 8px 16px;
  vertical-align: baseline;
  span {
    margin-left: 5px;
  }
}
</style>
