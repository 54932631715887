<template>
  <div>
    <div style="display: flex">
      <van-search
        v-model="value"
        @search="onSearch"
        placeholder="请输入订单编号"
        style="flex: 1"
      />
      <van-button type="danger" @click="handleLogout">退出</van-button>
    </div>
    <van-sticky>
      <van-tabs v-model="active" @click="onClick" :ellipsis="false">
        <van-tab
          :title="item.label"
          :name="item.value"
          v-for="(item, index) in statusList"
          :key="index"
        ></van-tab>
      </van-tabs>
    </van-sticky>
    <van-list
      v-model="loading"
      :finished="finished"
      @load="onLoad"
      finished-text="没有更多了"
    >
      <div v-for="(item, index) in orderList" :key="index">
        <div v-for="(orderItem, i) in item.record_data" :key="item.id + i">
          <div class="header" v-if="i === 0">
            <div class="time">订单号：{{ item.order_num }}</div>
            <div :class="['state' + item.status]">
              {{ item.status | filterStatus }}
            </div>
          </div>
          <van-card :thumb="require('@/assets/img/qiping@2x.png')">
            <template #title>
              <div class="card-title">{{ orderItem.name }}</div>
            </template>
            <template #price>
              <div class="price">
                单价:&nbsp;&nbsp;<span class="red"
                  >{{ orderItem.price }}元</span
                >
              </div>
            </template>
            <template #num> 数量x{{ orderItem.number }} </template>
            <template #footer v-if="i === item.record_data.length - 1">
              <!-- <div class="tip" v-if="item.bottle_data.length">
                回瓶：{{ filterhuiping(item.bottle_data) }}
              </div> -->
              <div class="footer">
                <div>
                  订单总价：<span class="total-price"
                    >¥{{ item.pay_price }}</span
                  >
                </div>
                <div>
                  <van-button
                    hairline
                    type="info"
                    size="mini"
                    v-if="item.status == 0"
                    @click="handlePeisong(item)"
                  >
                    去配送
                  </van-button>
                  <van-button
                    v-if="item.status == 1"
                    @click="handleDaoHang(item)"
                    hairline
                    type="info"
                    size="mini"
                    >去导航</van-button
                  >
                  <van-button
                    hairline
                    type="primary"
                    size="mini"
                    v-if="item.status == 1"
                    @click="handleFinish(item)"
                  >
                    已送达
                  </van-button>
                  <van-button
                    @click="handlePhone(item.phone)"
                    plain
                    hairline
                    type="info"
                    size="mini"
                    >联系客户</van-button
                  >
                </div>
              </div>
            </template>
          </van-card>
        </div>
      </div>
    </van-list>
    <!-- <van-action-sheet
      title="使用地图打开"
      cancel-text="取消"g
      v-model="show"
      :actions="actions"
      close-on-click-action
      @select="onSelect"
    /> -->
  </div>
</template>
<script>
import { getUserInfo } from '@/utils/index'
import { Dialog } from 'vant'
export default {
  name: 'peisong',
  data () {
    return {
      value: '',
      active: '',
      show: false,
      actions: [
        { name: '高德地图', id: 2 },
        { name: '百度地图', id: 1 }
      ],
      latitude: 0, // 纬度
      longitude: 0, // 经度
      orderList: [],
      pageNum: 1,
      address: '', // 打开导航使用
      statusList: [
        {
          label: '全部',
          value: '-1'
        },
        {
          label: '待发货',
          value: '0'
        },
        {
          label: '配送中',
          value: '1'
        },
        {
          label: '已取消',
          value: '3'
        },
        {
          label: '已完成',
          value: '2'
        }
      ],
      loading: false,
      finished: false,
      timer: null // 定时器
    }
  },
  filters: {
    filterStatus (val) {
      const statusMap = {
        0: '待发货',
        1: '配送中',
        2: '已完成',
        3: '已取消',
        4: '未付款',
        5: '已退款'
      }
      return statusMap[val]
    }
  },
  methods: {
    onLoad (val) {
      this.pageNum++
      this.getOrderList()
    },
    onClick (val) {
      this.orderList = []
      this.status = val
      this.pageNum = 1
      this.getOrderList()
    },
    onSearch () {
      this.orderList = []
      this.pageNum = 1
      this.getOrderList()
    },
    filterhuiping (arr) {
      let str = ''
      arr.forEach((item) => {
        str += item.name + 'x' + item.number + ' '
      })
      return str
    },
    onSubmit () {
      this.$router.push('/orderDetail')
    },
    handlePhone (phone) {
      window.location.href = 'tel:' + phone
    },
    handleDaoHang (item) {
      const that = this
      that.$wx.ready(function () {
        that.$wx.openLocation({
          type: 'gcj02',
          latitude: parseFloat(item.latitude),
          longitude: parseFloat(item.longitude),
          name: item.address,
          scale: 18,
          address: item.address,
          success: function (res) {
            console.log('成功', res)
          },
          fail: function (res) {
            console.log('失败', res)
          }
        })
      })
      that.$wx.error(function (res) {
        console.log('导航失败', res)
      })
      // this.navToMap(item)
      // this.address = item.address.split('|').join('')
      // this.show = true
    },
    onSelect (action) {
      this.navToMap(this.latitude, this.longitude, this.address, action.id)
    },
    // navToMap (latitude = 0, longitude = 0, name = '目的地', type = 1) {
    navToMap (item) {
      this.$http
        .post('/mall_wx/get_sdk_signature', {
          url: window.location.href.split('#')[0]
        })
        .then((res) => {
          if (res) {
            // that.$wx.config({
            //   debug: true, // 开启调试模式
            //   appId: 'wx8e50a21bf146f210', // 必填，公众号的唯一标识
            //   timestamp: res.data.timestamp, // 必填，生成签名的时间戳
            //   nonceStr: res.data.nonceStr, // 必填，生成签名的随机串
            //   signature: res.data.signature, // 必填，签名
            //   jsApiList: ['getLocation', 'openLocation']
            // })
          }
        })
      // let url
      // let lat, long
      // const u = navigator.userAgent
      // // 判断ios
      // const isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
      // // 判断Android
      // const isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1
      // if (type === 1) {
      //   // 百度地图 把获取到的非百度坐标转为百度坐标
      //   lat = latitude
      //   long = longitude
      // } else if (type === 2) {
      //   // 高德地图 把获取到的非GCJ-02坐标转为GCJ-02(火星坐标)
      //   lat = latitude
      //   long = longitude
      // }
      // if (isAndroid) {
      //   switch (type) {
      //     case 1: // 安卓百度地图
      //       url = `bdapp://map/direction?destination=name:${name}|latlng:${lat},${long}&coord_type=gcj02&mode=driving&src=andr.jianghu.jianhao`
      //       break
      //     case 2: // 安卓高德地图
      //       url = `amapuri://route/plan/?sourceApplication=mhc&dlat=${lat}&dlon=${long}&dname=${name}&dev=0&t=0`
      //       break
      //     default:
      //       break
      //   }
      // } else if (isIOS) {
      //   switch (type) {
      //     case 1: // IOS百度地图
      //       url = `baidumap://map/direction?destination=name:${name}|latlng:${lat},${long}&coord_type=gcj02&mode=driving&src=ios.jianghu.jianhao`
      //       break
      //     case 2: // IOS高德地图
      //       url = `iosamap://path?sourceApplication=mhc&dlat=&dlon=&dname=${name}&dev=0&t=0`
      //       break
      //     default:
      //       break
      //   }
      // }
      // if (url) {
      //   // window.location.href = 'iosamap://path?sourceApplication=mhc&dlat=&dlon=&dname=东久创新中心&dev=0&t=0'
      //   window.location.href = url
      // }
    },
    getOrderList () {
      this.loading = true
      this.$http
        .post('/mall_wx/user_order_record', {
          user_id: 0,
          keyword: this.value || null,
          status: this.status,
          pageNum: this.pageNum,
          pageSize: 10
        })
        .then((res) => {
          if (res.data.length === 0) {
            this.finished = true
            return
          } else {
            this.finished = false
          }
          this.orderList.push(...res.data)
        })
        .finally(() => {
          this.loading = false
        })
    },
    handlePeisong (item) {
      const userInfo = getUserInfo()
      Dialog.confirm({
        title: '提示',
        message: '确定要配送该订单吗？'
      }).then(() => {
        this.$http
          .post('/mall_wx/delivery_order', {
            record_id: item.id,
            user_id: userInfo.user_id
          })
          .then((res) => {
            if (res) {
              item.status = 1
              this.$toast.success('操作成功')
            }
          })
      })
    },
    handleFinish (item) {
      const userInfo = getUserInfo()
      Dialog.confirm({
        title: '提示',
        message: '确定要完成该订单吗？'
      }).then(() => {
        this.$http
          .post('/mall_wx/complete_order', {
            record_id: item.id,
            user_id: userInfo.user_id
          })
          .then((res) => {
            if (res) {
              item.status = 2
              this.$toast.success('订单已完成')
            }
          })
      })
    },
    handleLogout () {
      Dialog.confirm({
        title: '提示',
        message: '确定要退出吗？'
      }).then(() => {
        localStorage.clear()
        this.$router.replace('/login')
      })
    }
  },
  mounted () {
    const userInfo = getUserInfo()
    const that = this
    const getLocation = () => {
      this.$http
        .post('/mall_wx/get_sdk_signature', {
          url: window.location.href.split('#')[0]
        })
        .then((res) => {
          this.$wx.config({
            debug: false, // 开启调试模式
            appId: 'wx8e50a21bf146f210', // 必填，公众号的唯一标识
            timestamp: res.data.timestamp, // 必填，生成签名的时间戳
            nonceStr: res.data.nonceStr, // 必填，生成签名的随机串
            signature: res.data.signature, // 必填，签名
            jsApiList: ['getLocation', 'openLocation']
          })
          this.$wx.ready(() => {
            this.$wx.getLocation({
              type: 'gcj02', // 默认为wgs84的gps坐标，如果要返回直接给openLocation用的火星坐标，可传入'gcj02'
              isHighAccuracy: true,
              success: function (res) {
                // var latitude = res.latitude // 纬度，浮点数，范围为90 ~ -90
                // var longitude = res.longitude // 经度，浮点数，范围为180 ~ -180。
                // var speed = res.speed // 速度，以米/每秒计
                // var accuracy = res.accuracy // 位置精度
                console.log('获取定位成功', res)
                that.$http.post('/mall_wx/upload_gps', {
                  user_id: userInfo.user_id,
                  name: userInfo.user_name,
                  longitude: res.longitude,
                  latitude: res.latitude
                })
              },
              fail: (res) => {
                console.log('授权定位失败')
                that.$toast.fail('授权定位失败')
                console.log(res)
              }
            })
          })

          this.$wx.error(function (res) {
            that.$toast.fail('调取定位失败')
            console.log('调取定位失败')
          })
        })
    }
    getLocation()
    this.timer = setInterval(() => {
      getLocation()
    }, 60000)
    this.status = -1
    this.getOrderList()
  },
  beforeDestroy () {
    clearInterval(this.timer)
    this.timer = null
  }
}
</script>
<style lang="less" scoped>
.orderList {
  padding-top: 20px;
}
.van-card {
  padding-top: 0;
  // margin-top: 0;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
  margin-top: 5px;
  font-size: 12px;

  .time {
    display: flex;
    align-items: center;
    color: #6a6a6a;
  }
  //  0: '待发货',
  //  1: '配送中',
  //  2: '已完成',
  //  3: '已取消',
  //  4: '未付款',
  //  5: '已退款'
  .state0 {
    color: #cf0f0f;
  }
  .state1 {
    color: #4993ff;
  }
  .state2 {
    color: #07c160;
  }
  .state3 {
    color: #cf0f0f;
  }
  .state4 {
    color: #faab0c;
  }
  .state5 {
    color: #7232dd;
  }
}
.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 15px 0;
}
.card-title {
  font-size: 14px;
  margin-top: 10px;
}
.num {
  color: #323233;
  font-size: 14px;
}
.price {
  font-size: 14px;
  .red {
    font-size: 14px;
  }
}
.total-price {
  color: #cf0f0f;
}
.tip {
  margin-top: 10px;
  border-radius: 10px;
  background: #ffe2e2;
  padding: 10px 20px;
  color: #cf0f0f;
  text-align: left;
  font-size: 12px;
}
</style>
