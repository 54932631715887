<template>
  <div>
    <div class="personal">
      <div class="avatar">
        <!-- <img src="@/assets/img/qiping@2x.png"/> -->
      </div>
      <div class="name">{{phone}}</div>
    </div>
    <div class="entry">
      <img @click="$router.push('/orderList')" src="@/assets/img/wodedingdan@2x.png">
      <img @click="$router.push('/addressList')" src="@/assets/img/peisong@2x.png">
    </div>
    <div class="logout">
      <van-button type="danger" round block @click="handleLogout">退出登录</van-button>
    </div>
  </div>
</template>
<script>
export default {
  name: 'orderDetail',
  data () {
    return {
      phone: ''
    }
  },
  methods: {
    handleLogout () {
      localStorage.clear()
      this.$router.push('/login')
    }
  },
  mounted () {
    const userInfo = JSON.parse(localStorage.getItem('userInfo')) || {}
    this.phone = userInfo.phone
  }
}
</script>
<style lang="less" scoped>
.personal {
  width: 375px;
  height: 180px;
  padding-top: 30px;
  box-sizing: border-box;
  background: url("../assets/img/wodebg@2x.png") lightgray 50% / cover no-repeat;
  text-align: center;
  .avatar {
    width: 80px;
    height: 80px;
    margin: 0 auto;
    border-radius: 50%;
    background-color: #D9D9D9;
    img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
    }
  }
  .name {
    margin-top: 10px;
    font-size: 16px;
    color: #000;
  }
}
.entry {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 20px;
    img {
        width: 157px;
        height: 181px;
        margin-bottom: 20px;
    }
}
.logout {
  padding: 0 20px;
}
</style>
