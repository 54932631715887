<template>
  <div>
    <backHome></backHome>
    <div>
      <van-card
        :thumb="require('@/assets/img/qiping@2x.png')"
        v-for="(item, index) in productList"
        :key="index"
      >
        <template #title>
          <div class="card-title">{{ item.name }}</div>
        </template>
        <!-- <template #desc v-if="item.deposit">
          <div class="deposit">押金{{ item.deposit }}元</div>
        </template> -->
        <template #price>
          <div class="price">单价:&nbsp;&nbsp;<span class="red">{{item.price}}元</span></div>
        </template>
        <template #num>
          <div class="num">数量x{{ item.count }}</div>
        </template>
      </van-card>
      <div class="total-price">
        <span>订单总价：</span>
        <span class="price">¥{{ totalPrice }}</span>
      </div>
    </div>
    <div>
      <!-- <van-cell is-link @click="handleHuiPing()">
        <div class="content">{{ huipingList.length? totalhuiping:'请选择回瓶种类和数量' }}</div>
        <template #title>
          <div class="cell-item">
            <img src="@/assets/svg/huiping.svg" />
            <span>回瓶</span>
          </div>
        </template>
      </van-cell> -->
      <van-cell >
        <div class="content" v-if="coupon_price">满{{use_min_price}} - {{coupon_price}}</div>
        <div class="content" v-else>无</div>
        <template #title>
          <div class="cell-item">
            <img src="@/assets/svg/hongbao.svg" />
            <span>红包</span>
          </div>
        </template>
      </van-cell>
      <van-cell is-link @click="handleDiZhi()">
        <div class="content">
          {{ address ? address : '请选择地址' }}
        </div>
        <template #title>
          <div class="cell-item">
            <img src="@/assets/svg/dizhi.svg" />
            <span>地址</span>
          </div>
        </template>
      </van-cell>
      <van-cell >
        <div class="content">{{freight}}元</div>
        <template #title>
          <div class="cell-item">
            <img src="@/assets/svg/yunfei.svg" />
            <span>运费</span>
          </div>
        </template>
      </van-cell>
       <!-- <van-cell is-link>
        <div class="content">请选择提货方式</div>
        <template #title>
          <div class="cell-item">
            <img src="@/assets/svg/peisong.svg" />
            <span>配送</span>
          </div>
        </template>
      </van-cell> -->
      <!-- <van-cell is-link>
        <div class="content">请选择预约时间</div>
        <template #title>
          <div class="cell-item">
            <img src="@/assets/svg/shijian.svg" />
            <span>时间</span>
          </div>
        </template>
      </van-cell> -->
      <van-field
        v-model="notes"
        rows="4"
        label=""
        type="textarea"
        maxlength="256"
        placeholder="这里可以备注上门时间等要求～"
      />
    </div>
    <van-submit-bar :price="price * 100" button-text="立即支付" @submit="onSubmit">
      <template #default>
        <van-badge :content="totalCount.toString()">
          <van-icon name="cart-o" color="#ee0a24" :size="25" />
        </van-badge>
      </template>
    </van-submit-bar>
  </div>
</template>
<script>
import {
  setShoppingCart,
  getUserInfo,
  getShoppingCart
  // getHuiping
} from '@/utils/index'
import backHome from '@/components/backHome.vue'
import Big from 'big.js'
export default {
  name: 'orderDetail',
  components: {
    backHome
  },
  data () {
    return {
      notes: null,
      productList: [],
      totalPrice: 0,
      totalCount: 0,
      price: 0, // 实际支付
      coupon_price: 0, // 优惠券
      freight: 0, // 运费
      use_min_price: 0, // 满减
      address: '', // 地址
      address_id: '', // 地址id
      huipingList: [], // 回瓶列表
      addressList: [], // 地址列表
      loading: false // 禁止多次调用订单接口
    }
  },
  computed: {
    totalhuiping () {
      let str = ''
      this.huipingList.forEach(item => {
        str += item.name + 'x' + item.count + ' '
      })
      return str
    }
  },
  methods: {
    onLoad () {},
    onBridgeReady (params, orderInfo) {
      const that = this
      WeixinJSBridge.invoke(
        'getBrandWCPayRequest', {
          appId: 'wx8e50a21bf146f210', // 公众号名称，由商户传入
          timeStamp: params.timeStamp, // 时间戳
          nonceStr: params.nonceStr, // 随机串
          package: params.package,
          signType: params.signType, // 微信签名方式：
          paySign: params.paySign // 微信签名
        },
        function (res) {
          // 支付成功
          if (res.err_msg === 'get_brand_wcpay_request:ok') {
            // 使用以上方式判断前端返回,微信团队郑重提示：
            // res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
            that.$toast.success('下单成功')
            setShoppingCart({})
            that.$router.push('/orderList')
          }
          // 支付过程中用户取消
          if (res.err_msg === 'get_brand_wcpay_request:cancel') {
            const userInfo = getUserInfo()
            that.$http.post('/mall_wx/cancel_order', {
              record_id: orderInfo.record_id,
              user_id: userInfo.user_id
            }).then(res => {
              if (res) {
                that.$toast.success({
                  message: '订单已取消',
                  onClose: () => {
                    setShoppingCart({})
                    that.$router.push('/orderList')
                  }
                })
              }
            })
          }
          // 支付失败
          if (res.err_msg === 'get_brand_wcpay_request:fail') {
            const userInfo = getUserInfo()
            that.$http.post('/mall_wx/cancel_order', {
              record_id: orderInfo.record_id,
              user_id: userInfo.user_id
            }).then(res => {
              if (res) {
                that.$toast.success({
                  message: '订单已取消',
                  onClose: () => {
                    setShoppingCart({})
                    that.$router.push('/orderList')
                  }
                })
              }
            })
          }
          /**
            * 其它
            * 1、请检查预支付会话标识prepay_id是否已失效
            * 2、请求的appid与下单接口的appid是否一致
            * */
          if (res.err_msg === '调用支付JSAPI缺少参数：total_fee') {

          }
        })
    },
    onSubmit () {
      if (this.loading) {
        this.$toast.fail('请稍后再试')
        return
      }
      const shoppingCart = getShoppingCart()
      if (Object.keys(shoppingCart).length === 0) {
        return this.$toast.fail('请先选择商品')
      }
      if (!this.address_id) {
        return this.$toast.fail('请选择地址')
      }
      const userInfo = getUserInfo()
      const goods = Object.values(shoppingCart).map(item => {
        return {
          p_data_id: item.id,
          number: item.count
        }
      })
      const bottleReturn = this.huipingList.map(item => {
        return {
          bot_dep_id: item.bot_dep_id,
          number: item.count
        }
      })
      this.$http.post('/mall_wx/place_order', {
        user_id: userInfo.user_id,
        address_id: this.address_id,
        goods: goods,
        bottle_return: bottleReturn,
        notes: this.notes
      }).then(res => {
        if (res) {
          if (typeof WeixinJSBridge === 'undefined') {
            if (document.addEventListener) {
              document.addEventListener('WeixinJSBridgeReady', this.onBridgeReady, false)
            } else if (document.attachEvent) {
              document.attachEvent('WeixinJSBridgeReady', this.onBridgeReady)
              document.attachEvent('onWeixinJSBridgeReady', this.onBridgeReady)
            }
          } else {
            this.onBridgeReady(res.data[0], res.data[1])
          }
        }
      }).finally(() => {
        this.loading = false
      })
    },
    handleHuiPing () {
      this.$router.push('/huipingList')
    },
    handleDiZhi () {
      this.$router.push('/addressList?showRadio=1&address_id=' + this.address_id)
    }
  },
  async mounted () {
    // 查询优惠券，回瓶
    const userInfo = getUserInfo()
    await this.$http.post('/mall_wx/other_info', {
      user_id: userInfo.user_id
    }).then(res => {
      this.coupon_price = res.coupon_price
      this.use_min_price = res.use_min_price
      this.freight = res.freight
    })
    // 计算价格
    const shoppingCart = getShoppingCart()
    this.productList = Object.values(shoppingCart)
    this.totalPrice = Object.values(shoppingCart).reduce((total, item) => {
      return new Big(total).plus(new Big(item.count).times(item.price))
      // return total + (item.count * item.price)
    }, 0)
    // 计算回瓶
    // const huiping = getHuiping()
    // this.huipingList = Object.values(huiping)
    // const huipingPrice = Object.values(huiping).reduce((total, item) => {
    //   return total + (item.count * item.price)
    // }, 0)
    if (this.totalPrice >= this.use_min_price) {
      this.price = new Big(this.totalPrice).minus(this.coupon_price).plus(this.freight)
      // this.price = this.totalPrice - this.coupon_price + this.freight
    } else {
      this.price = new Big(this.totalPrice).plus(this.freight)
      // this.price = this.totalPrice + this.freight
    }
    console.log(this.price)
    this.totalCount = Object.values(shoppingCart).reduce((total, item) => {
      return new Big(total).plus(item.count)
      // return total + item.count
    }, 0)
  },
  watch: {
    $route: {
      handler (newVal) {
        if (newVal.query.address_id) {
          console.log(newVal.query.address_id)
          this.address = this.$route.query.address?.split('|').join('')
          this.address_id = this.$route.query.address_id
        } else {
          // 查询默认地址
          const userInfo = getUserInfo()
          this.$http.post('/mall_wx/show_address', {
            user_id: userInfo.user_id
          }).then(res => {
            this.addressList = res.data
            const result = res.data.find(item => {
              return item.is_default === 0
            })
            if (result) {
              this.address = result.address.split('|').join('')
              this.address_id = result.address_id
            }
          })
        }
      },
      immediate: true
    }
  }
}
</script>
<style lang="less" scoped>
.card-title {
  font-size: 16px;
  margin-top: 10px;
}
.num {
  color: #323233;
  font-size: 16px;
}
.deposit {
  margin-top: 10px;
}
.price {
  font-size: 16px;
  .red {
    font-size: 16px;
  }
}
.total-price {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 16px;
  margin: 15px 0;
  .price {
    color: #cf0f0f;
    font-size: 16px;
    font-weight: 500;
  }
}
.cell-item {
  display: flex;
  align-items: center;
  img {
    width: 20px;
    height: 20px;
    margin-right: 5px;
  }
}
// ::v-deep .van-cell__title {
//   width: 20px !important;
//   flex: auto !important;
// }
// ::v-deep .van-cell__value {
//   flex: 1 !important;
//   flex-shrink: 0 !important;
// }
</style>
