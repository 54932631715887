<template>
  <div class="login">
    <img class="img" src="@/assets/img/logo@2x.png" alt="logo" />
    <van-form @submit="onSubmit">
      <van-field
        v-model="phone"
        name="手机号"
        placeholder="请输入手机号"
        class="login-phone"
      />
      <div class="login-btn">
        <van-button  block type="danger" native-type="submit"
          >登录</van-button
        >
      </div>
      <div class="login-btn mt-0">
        <van-button  block type="info" @click="$router.push('/register')"
          >注册</van-button
        >
      </div>
    </van-form>
  </div>
</template>
<script>
import { setUserInfo } from '@/utils/index'
export default {
  name: 'login',
  data () {
    return {
      phone: ''
    }
  },
  methods: {
    onSubmit () {
      if (!this.phone) {
        this.$toast.fail('请输入手机号')
        return
      }
      this.$http.post('/mall_wx/login', {
        phone: this.phone,
        pwd: 123456
      }).then(res => {
        localStorage.clear()
        setUserInfo(res.data)
        // 如果是配送员只能进入配送页面
        if (res.data.role_id === 5) {
          this.$router.push('/peisong')
        } else {
          this.$router.push('/index')
        }
      })
    }
  }

}
</script>

<style lang="less" scoped>
.login {
  height: 100%;
  background: url("../assets/img/index@2x.png") no-repeat lightgray 50% / cover;
  padding-top: 20px;
  .img {
    display: block;
    width: 120px;
    height: 120px;
    margin: 0 auto;
    margin-top: 36px;
  }
  .login-phone {
    width: 335px;
    margin: 0 auto;
    margin-top: 80px;
  }
  .login-btn {
    width: 335px;
    margin: 0 auto;
    margin-top: 80px;
  }
  .mt-0 {
    margin-top: 30px;
  }
}
</style>
