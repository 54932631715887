<template>
  <div>
    <van-address-edit
      :area-list="areaList"
      show-delete
      show-set-default
      :area-columns-placeholder="['请选择', '请选择', '请选择']"
      :address-info="addressInfo"
      @save="onSave"
      @delete="onDelete"
    />
  </div>
</template>
<script>
import { areaList } from '@vant/area-data'
export default {
  name: 'addressListAdd',
  data () {
    return {
      areaList,
      addressInfo: {
        areaCode: '341122'
      }
    }
  },
  methods: {
    onSave (val) {
      console.log(val)
      const userInfo = JSON.parse(localStorage.getItem('userInfo'))
      // 新增
      if (!this.$route.query.address_id) {
        this.$http.post('/mall_wx/add_address', {
          user_id: userInfo.user_id,
          name: val.name,
          phone: val.tel,
          address: `${val.province}${val.city}${val.county}|${val.addressDetail}`,
          is_default: val.isDefault ? 0 : 1,
          area_code: val.areaCode
        }).then(res => {
          this.$toast.success('添加成功')
          this.$router.push('/addressList')
        })
      } else {
        // 编辑
        this.$http.post('/mall_wx/update_address', {
          user_id: userInfo.user_id,
          name: val.name,
          phone: val.tel,
          address: `${val.province}${val.city}${val.county}|${val.addressDetail}`,
          is_default: val.isDefault ? 0 : 1,
          area_code: val.areaCode,
          address_id: this.$route.query.address_id
        }).then(res => {
          this.$toast.success('编辑成功')
          this.$router.push('/addressList')
        })
      }
    },
    onDelete (val) {
      this.$http.post('/mall_wx/del_address', {
        address_id: this.$route.query.address_id
      }).then(res => {
        this.$toast.success('删除成功')
        this.$router.push('/addressList')
      })
    }
  },
  mounted () {
    if (this.$route.query.address_id) {
      const addressInfo = JSON.parse(localStorage.getItem('addressInfo'))
      this.addressInfo = {
        ...addressInfo,
        addressDetail: addressInfo.address2.split('|')[1],
        isDefault: addressInfo.is_default === 0,
        areaCode: addressInfo.area_code
      }
    }
  }
}
</script>
<style lang="less" scoped></style>
