<template>
  <div class="index">
    <img
      @click="handleClick('/shoppingMall')"
      class="img"
      src="@/assets/img/dingqi@2x.png"
      alt="dingqi"
    />
    <img
      @click="handleClick('/register')"
      class="img"
      src="@/assets/img/kaihu@2x.png"
      alt="kaihu"
    />
    <img
      @click="handleClick('/mine')"
      class="img"
      src="@/assets/img/mine@2x.png"
      alt="mine"
    />
  </div>
</template>

<script>
export default {
  name: 'index',
  data () {
    return {
    }
  },
  methods: {
    handleClick (path) {
      this.$router.push(path)
    }
  }

}
</script>
<style lang="less" scoped>
.index {
  height: 100%;
  background: url("../assets/img/index@2x.png") no-repeat lightgray 50% / cover;
  padding-top: 20px;
}
.img {
  display: block;
  width: 335px;
  margin: 0 auto 20px;
}
</style>
