var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"app-container"},[_vm._m(0),_c('div',[_c('van-form',{on:{"submit":_vm.onSubmit}},[_c('van-field',{attrs:{"name":"username","label":"姓名","placeholder":"姓名","rules":[{ required: true, message: '请输入姓名' }]},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}}),_c('van-field',{attrs:{"name":"phone","label":"手机号","placeholder":"手机号","rules":[
          { required: true, message: '请输入手机号' },
          { validator: _vm.validatorPhone, message: '请输入正确手机号' },
        ]},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}}),_c('van-field',{attrs:{"clickable":"","name":"area","value":_vm.area,"label":"地区","placeholder":"地区","rules":[{ required: true, message: '请选择地区' }]},on:{"click":function($event){_vm.showArea = true}}}),_c('van-popup',{attrs:{"position":"bottom"},model:{value:(_vm.showArea),callback:function ($$v) {_vm.showArea=$$v},expression:"showArea"}},[_c('van-area',{attrs:{"area-list":_vm.areaList,"value":_vm.city},on:{"confirm":_vm.onConfirm,"cancel":function($event){_vm.showArea = false}}})],1),_c('van-field',{attrs:{"name":"address","label":"详细地址","rows":"3","type":"textarea","placeholder":"详细地址","rules":[
          { required: true, message: '请输入详细地址' },
        ]},model:{value:(_vm.address),callback:function ($$v) {_vm.address=$$v},expression:"address"}}),_c('div',{staticStyle:{"margin":"16px"}},[_c('van-button',{attrs:{"round":"","block":"","type":"danger","native-type":"submit"}},[_vm._v("提交")])],1)],1)],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"banner"},[_c('img',{attrs:{"src":require("@/assets/img/xinren@2x.png"),"alt":""}})])
}]

export { render, staticRenderFns }