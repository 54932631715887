<template>
    <div>
        <van-notice-bar
        wrapable
        :scrollable="false"
        text="请根据实际交予配送人员钢瓶的数量和种类选择对应的回瓶数量和种类"
        />
        <van-card
          :thumb="require('@/assets/img/qiping@2x.png')"
          v-for="item in list"
          :key="item.bot_dep_id"
        >
          <template #title>
            <div class="card-title">{{ item.name }}</div>
          </template>
          <template #price>
            <div class="price">
              押金:&nbsp;&nbsp;<span class="red">{{item.price}}元</span>
            </div>
          </template>
          <template #num>
            <van-stepper
              v-model="item.count"
              :max="item.number"
              :disabled="item.disabled"
              :min="0"
              theme="round"
              button-size="22"
              disable-input
              @change="handleChange(item)"
            />
          </template>
        </van-card>
        <van-submit-bar :price="totalPrice * 100" button-text="确定" label="合计抵扣：" @submit="onSubmit">
          <template #default>
            共选择数量{{ totalCount }}
          </template>
        </van-submit-bar>
    </div>
</template>
<script>
// import { Toast } from 'vant'
import { getUserInfo, setHuiping, getHuiping } from '@/utils/index'
export default {
  name: 'huipingList',
  data () {
    return {
      list: [],
      totalCount: 0,
      totalPrice: 0
    }
  },
  methods: {
    onSubmit () {
      this.$router.push('/orderDetail')
    },
    handleChange (val) {
      console.log(val)
      const huiping = getHuiping()

      if (val.count) {
        huiping[val.bot_dep_id] = val
      } else {
        delete huiping[val.bot_dep_id]
      }
      this.calcPrice(huiping)
      setHuiping(huiping)
    },
    calcPrice (huiping) {
      this.totalPrice = Object.values(huiping).reduce((total, item) => {
        return total + item.count * item.price
      }, 0)
      this.totalCount = Object.values(huiping).reduce((total, item) => {
        return total + item.count
      }, 0)
    }
  },
  mounted () {
    // 查询优惠券，回瓶
    const userInfo = getUserInfo()
    this.$http.post('/mall_wx/other_info', {
      user_id: userInfo.user_id
    }).then(res => {
      const huiping = getHuiping()
      this.list = res.data.map(item => {
        if (item.bot_dep_id in huiping) {
          item.count = huiping[item.bot_dep_id].count
        } else {
          item.count = 0
        }
        return item
      })
      this.calcPrice(huiping)
      const shoppingCart = JSON.parse(localStorage.getItem('shoppingCart')) || {}
      const nameList = Object.values(shoppingCart).map(item => item.name)
      this.list.forEach(item => {
        if (nameList.includes(item.name)) {
          item.disabled = false
        } else {
          item.disabled = true
        }
      })
    })
  }
}
</script>
<style lang="less" scoped>

</style>
