<template>
  <div>
    <van-address-list
      v-model="chosenAddressId"
      :list="list"
      default-tag-text="默认地址"
      :add-button-text="$route.query.showRadio ? '确定' : '新增地址'"
      @add="onAdd"
      @edit="onEdit"
    />
  </div>
</template>
<script>
import { Toast } from 'vant'
export default {
  name: 'addressList',
  data () {
    return {
      chosenAddressId: '',
      list: []
    }
  },
  methods: {
    onAdd () {
      if (this.$route.query.showRadio) {
        if (!this.chosenAddressId) {
          return Toast('请选择地址')
        }
        const result = this.list.find(item => {
          return item.id === this.chosenAddressId
        })
        this.$router.push({
          path: '/orderDetail',
          query: {
            address_id: result.address_id,
            address: result.address
          }
        })
      } else {
        this.$router.push('/addressListAdd')
      }
    },
    onEdit (item, index) {
      console.log(item, index)
      localStorage.setItem('addressInfo', JSON.stringify(item))
      this.$router.push({
        path: '/addressListAdd',
        query: {
          address_id: item.id
        }
      })
    },
    hiddenRadio () {
      // 隐藏radio
      const radios = document.querySelectorAll('.van-radio__icon')
      console.log('radios', radios)
      if (radios) {
        radios.forEach((item) => {
          item.style.display = 'none'
        })
      }
    }
  },
  mounted () {
    const userInfo = JSON.parse(localStorage.getItem('userInfo'))
    this.$http.post('/mall_wx/show_address', {
      user_id: userInfo.user_id
    }).then(res => {
      this.list = res.data.map(item => {
        item.tel = item.phone
        item.id = item.address_id
        item.isDefault = item.is_default === 0
        item.address2 = item.address
        item.address = item.address.split('|').join('')
        return item
      })
      if (!this.$route.query.showRadio) {
        this.$nextTick(() => {
          this.hiddenRadio()
        })
      }
      if (this.$route.query.address_id) {
        this.chosenAddressId = parseInt(this.$route.query.address_id)
      }
      console.log(this.list)
    })
  }
}
</script>
<style lang="less" scoped></style>
