<template>
  <div class="orderList">
    <backHome></backHome>
    <van-list
        v-model="loading"
        :finished="finished"
        @load="onLoad"
        finished-text="没有更多了"
      >
      <div v-for="(item, index) in orderList" :key="index">
        <div v-for="(orderItem, i) in item.record_data" :key="item.id + i">
          <div class="header" v-if="i === 0">
            <div class="time">
              <van-icon name="clock-o" size="16" class="icon" />
              <!-- 购买时间：{{ item.pay_time }} -->
              订单号：{{ item.order_num }}
            </div>
            <div :class="['state' + item.status ]">{{ item.status | filterStatus }}</div>
          </div>
          <van-card :thumb="require('@/assets/img/qiping@2x.png')">
            <template #title>
              <div class="card-title">{{ orderItem.name }}</div>
            </template>
            <template #price>
              <div class="price">
                单价:&nbsp;&nbsp;<span class="red">{{orderItem.price}}元</span>
              </div>
            </template>
            <template #num> 数量x{{ orderItem.number }} </template>
            <template #footer v-if="i === item.record_data.length - 1">
              <div class="footer">
                <div>订单总价：<span class="total-price">¥{{ item.pay_price }}</span></div>
                <div>
                  <van-button plain hairline type="danger" size="mini"
                  v-if="item.status == 0 || item.status == 4"
                  @click="handleCancelOrder(item)"
                    >取消订单</van-button
                  >
                  <!-- <van-button plain hairline type="primary" size="mini"
                  @click="handlePayment(item.id)"
                    >去支付</van-button
                  > -->
                </div>
              </div>
            </template>
          </van-card>
        </div>
      </div>
    </van-list>
  </div>
</template>
<script>
import { Dialog } from 'vant'
import { getUserInfo } from '@/utils/index'
import backHome from '@/components/backHome.vue'
export default {
  name: 'orderList',
  components: {
    backHome
  },
  data () {
    return {
      loading: false,
      finished: false,
      orderList: [],
      pageNum: 1
    }
  },
  filters: {
    filterStatus (val) {
      const statusMap = {
        0: '待发货',
        1: '配送中',
        2: '已完成',
        3: '已取消',
        4: '未付款',
        5: '已退款'
      }
      return statusMap[val]
    }
  },
  methods: {
    onLoad (val) {
      this.pageNum++
      this.getOrderList()
    },
    handleCancelOrder (item) {
      Dialog.confirm({
        title: '提示',
        message: '确定要取消订单吗？'
      }).then(() => {
        const userInfo = getUserInfo()
        this.$http.post('/mall_wx/cancel_order', {
          record_id: item.id,
          user_id: userInfo.user_id
        }).then(res => {
          if (res) {
            this.$toast.success('取消成功')
            item.status = 3
          }
        })
      })
    },
    getOrderList () {
      const userInfo = getUserInfo()
      this.loading = true
      this.$http.post('/mall_wx/user_order_record', {
        user_id: userInfo.user_id,
        keyword: null,
        status: -1,
        pageNum: this.pageNum,
        pageSize: 10
      }).then(res => {
        if (res.data.length === 0) {
          this.finished = true
          return
        } else {
          this.finished = false
        }
        this.orderList.push(...res.data)
      }).finally(() => {
        this.loading = false
      })
      // this.loading = true
      // setTimeout(() => {
      //   this.orderList.push({
      //     record_data: [{
      //       id: Math.random()
      //     }, {
      //       id: Math.random()
      //     }, {
      //       id: Math.random()
      //     }, {
      //       id: Math.random()
      //     }, {
      //       id: Math.random()
      //     }, {
      //       id: Math.random()
      //     }, {
      //       id: Math.random()
      //     }, {
      //       id: Math.random()
      //     }, {
      //       id: Math.random()
      //     }, {
      //       id: Math.random()
      //     }, {
      //       id: Math.random()
      //     }]
      //   })
      //   console.log(1)
      //   if (this.orderList.length === 10) {
      //     this.finished = true
      //   } else {
      //     this.finished = false
      //   }
      //   this.loading = false
      // }, 1000)
    },
    handlePayment () {

    }
  },
  mounted () {
    this.getOrderList()
  }
}
</script>
<style lang="less" scoped>
.orderList {
}
.van-card {
  padding-top: 0;
  margin-top: 0;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 16px;
  font-size: 12px;
  .time {
    display: flex;
    align-items: center;
    color: #6a6a6a;
    .icon {
        margin-right: 5px;
    }
  }
    //  0: '待发货',
    //  1: '配送中',
    //  2: '已完成',
    //  3: '已取消',
    //  4: '未付款',
    //  5: '已退款'
  .state0 {
    color: #cf0f0f;
  }
  .state1 {
    color: #4993FF;
  }
  .state2 {
    color: #07c160;
  }
  .state3 {
    color: #cf0f0f;
  }
  .state4 {
    color: #faab0c;
  }
  .state5 {
    color: #7232dd;
  }
}
.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 15px 0;
}
.card-title {
  font-size: 14px;
  margin-top: 10px;
}
.num {
  color: #323233;
  font-size: 14px;
}
.price {
  font-size: 14px;
  .red {
    font-size: 14px;
  }
}
.total-price {
    color: #cf0f0f;
}
</style>
