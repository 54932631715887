<template>
  <div class="app-container">
    <div class="banner">
      <img src="@/assets/img/shangcheng@2x.png" alt="" />
    </div>
    <div>
      <van-sticky>
          <van-tabs v-model="active" @click="onClick" :ellipsis="false">
            <van-tab :title="item.name" v-for="item in typeList" :key="item.id"></van-tab>
          </van-tabs>
      </van-sticky>
      <!-- finished-text="没有更多了" -->
      <van-list
        v-model="loading"
        :finished="finished"
        @load="onLoad"
      >
        <van-card
          :thumb="require('@/assets/img/qiping@2x.png')"
          v-for="(item, index) in productList"
          :key="index"
        >
          <template #title>
            <div class="card-title">{{ item.name }}</div>
          </template>
          <!-- <template #desc v-if="item.deposit">
            <div class="deposit">押金{{ item.deposit }}元</div>
          </template> -->
          <template #price>
            <div class="price">
              单价:&nbsp;&nbsp;<span class="red">{{item.price}}元</span>
            </div>
          </template>
          <template #num>
            <van-stepper
              v-model="item.count"
              min="0"
              theme="round"
              button-size="22"
              disable-input
              @change="handleChange(item)"
            />
          </template>
        </van-card>
        <div class="noData" v-if="!productList.length">
            <img  src="@/assets/img/noData@2x.png" alt="noData">
        </div>
      </van-list>
    </div>
    <van-submit-bar :price="totalPrice * 100" button-text="提交订单" @submit="onSubmit">
      <template #default>
        <van-badge :content="totalCount.toString()">
          <van-icon name="cart-o" color="#ee0a24" :size="25" />
        </van-badge>
      </template>
    </van-submit-bar>
  </div>
</template>
<script>
import { checkLogin, getShoppingCart, setShoppingCart } from '@/utils/index'
import Big from 'big.js'
export default {
  name: 'shoppingMall',
  data () {
    return {
      loading: false,
      finished: true,
      active: '',
      typeList: [],
      productList: [],
      totalPrice: 0,
      totalCount: 0
    }
  },
  methods: {
    onClick (val) {
      this.getProductList(this.typeList[val].id)
    },
    onLoad () {},
    onSubmit () {
      const isLogin = checkLogin()
      if (!isLogin) {
        this.$toast.fail({
          message: '请先登录',
          onClose: () => {
            this.$router.push('/login')
          }
        })
        return
      }
      const shoppingCart = getShoppingCart()
      if (Object.keys(shoppingCart).length === 0) {
        return this.$toast.fail('请先选择商品')
      }
      this.$router.push('/orderDetail')
    },
    getProductList (categoryId) {
      this.$http.post('/mall_wx/productslist', {
        category_id: categoryId
      }).then(res => {
        const shoppingCart = getShoppingCart()
        this.productList = res.data.map(item => {
          if (item.id in shoppingCart) {
            item.count = shoppingCart[item.id].count
          } else {
            item.count = 0
          }
          return item
        })
        this.totalPrice = Object.values(shoppingCart).reduce((total, item) => {
          return new Big(total).plus(new Big(item.count).times(item.price))
          // return total + (item.count * item.price)
        }, 0)
        this.totalCount = Object.values(shoppingCart).reduce((total, item) => {
          return new Big(total).plus(item.count)
          // return total + item.count
        }, 0)
      })
    },
    handleChange (val) {
      console.log(val)
      const shoppingCart = getShoppingCart()
      if (val.count) {
        shoppingCart[val.id] = val
      } else {
        delete shoppingCart[val.id]
      }
      this.totalPrice = Object.values(shoppingCart).reduce((total, item) => {
        return new Big(total).plus(new Big(item.count).times(item.price))
        // return total + (item.count * item.price)
      }, 0)
      this.totalCount = Object.values(shoppingCart).reduce((total, item) => {
        return new Big(total).plus(item.count)
        // return total + item.count
      }, 0)
      setShoppingCart(shoppingCart)
    }
  },
  mounted () {
    this.$http.post('/mall_wx/classifications').then(res => {
      this.typeList = res.data
      this.getProductList(res.data[0].id)
    })
  }
}
</script>
<style lang="less" scoped>
.app-container {
  padding-bottom: 50px;
}
.banner {
  img {
    width: 375px;
    height: 160px;
  }
}
.card-title {
  font-size: 16px;
  margin-top: 10px;
}
.deposit {
  margin-top: 10px;
}
.price {
  font-size: 16px;
  .red {
    color: #ee0a24;
    font-size: 16px;
    font-weight: 700;
  }
}
.noData {
    text-align: center;
    img {
        width: 200px;
    }
}
</style>
