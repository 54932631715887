/**
 *校验用户是否登录
 * @returns {Boolean}
 */
export const checkLogin = () => {
  const userInfo = JSON.parse(localStorage.getItem('userInfo')) || {}
  if (!userInfo.user_id) {
    return false
  }
  return true
}

/**
 *设置用户信息
 * @returns {Object}
 */
export const setUserInfo = (data) => {
  localStorage.setItem('userInfo', JSON.stringify(data))
}

/**
 *获取用户信息
 * @returns {Object}
 */
export const getUserInfo = () => {
  return JSON.parse(localStorage.getItem('userInfo')) || {}
}

/**
 *设置购物车信息
 * @returns {Object}
 */
export const setShoppingCart = (data) => {
  localStorage.setItem('shoppingCart', JSON.stringify(data))
}

/**
   *获取购物车信息
   * @returns {Object}
   */
export const getShoppingCart = () => {
  return JSON.parse(localStorage.getItem('shoppingCart')) || {}
}

/**
 *设置回瓶信息
 * @returns {Object}
 */
export const setHuiping = (data) => {
  localStorage.setItem('huiping', JSON.stringify(data))
}

/**
   *获取回瓶信息
   * @returns {Object}
   */
export const getHuiping = () => {
  return JSON.parse(localStorage.getItem('huiping')) || {}
}
